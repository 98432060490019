<template>
  <section data-component-name="Awards">
    <div class="wrapper">
      <h3 v-html="$t('Awards.title')" />

      <Buttons.Regular
        fill="outline"
        class="awards-swiper-prev-slide"
        :aria-label="$t('Awards.prev-btn.title')"
      >
        <SvgArrowBackward />
      </Buttons.Regular>

      <Buttons.Regular
        fill="outline"
        class="awards-swiper-next-slide"
        :aria-label="$t('Awards.next-btn.title')"
      >
        <SvgArrowForward />
      </Buttons.Regular>
    </div>

    <Swiper
      class="awardsSwiper"
      :modules="[Navigation]"
      :slides-offset-before="sliderOffset"
      :slides-offset-after="sliderOffset"
      v-bind="SWIPER_CONFIG"
      :navigation="{
        prevEl: '.awards-swiper-prev-slide',
        nextEl: '.awards-swiper-next-slide',
      }"

    >
      <SwiperSlide
        v-for="({ href, ...imgAttrs }, index) of AWARDS"
        :key="index"
      >
        <NuxtLink
          :to="href.startsWith('https') ? href : localePathWithSlash(href)"
          target="_blank"
        >
          <NuxtImg
            v-bind="imgAttrs"

            densities="1x 2x"
            format="webp"
            loading="lazy"
          />
        </NuxtLink>
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script lang="ts" setup>
// components
import Buttons from '~/components/redesign/Buttons';

// swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// composables
import { useI18n } from '#i18n';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';
import { useWindowSize } from '@vueuse/core';

const { t } = useI18n();
const localePathWithSlash = useLocalePathWithSlash();

const AWARDS = [
  {
    href: 'https://www.ciocoverage.com/10-fastest-growing-zero-trust-security-solution-providers-2024-25/',
    src: 'redesign/images/awards/CIO_COVERAGE_ZERO_TRUST.webp',
    alt: t('Awards.award-0.alt'),
    width: '256px',
    height: '256px',
    sizes: 'xs: md:128px xl:256px',
  },
  {
    href: 'https://storage-awards.com/',
    src: 'redesign/images/awards/STORAGE_AWARDS.webp',
    alt: t('Awards.award-1.alt'),
    width: '256px',
    height: '191px',
    sizes: 'xs: md:128px xl:256px'
  },
  {
    href: 'https://www.crn.com/news/storage/2024/storage-100-the-digital-bridge-between-the-cloud-and-on-premises-worlds',
    src: 'redesign/images/awards/CRN_100_2024.webp',
    alt: t('Awards.award-2.alt'),
    width: '130px',
    height: '256px',
    sizes: 'xs: md:65.1px xl:130px'
  },
  {
    href: '/newsroom/press-releases/object-first-selected-for-2024-crn-partner-program-guide/',
    src: 'redesign/images/awards/CRN_PPG_2024.webp',
    alt: t('Awards.award-3.alt'),
    width: '135px',
    height: '256px',
    sizes: '135px xs: md:67.5px xl:135px'

  },
  {
    href: '/newsroom/press-releases/vitaly-sukhovsky-recognized-as-2024-crn-channel-chief/',
    src: 'redesign/images/awards/CRN_CHANNEL_2024.webp',
    alt: t('Awards.award-4.alt'),
    width: '160px',
    height: '256px',
    sizes: '160px xs: md:67.6px xl:160px'
  },
  {
    href: '/newsroom/press-releases/crn-recognizes-meredith-frick-and-kelly-wells-on-the-2024-women-of-the-channel-list/',
    src: 'redesign/images/awards/CRN_WOMAN_CHANNEL.webp',
    alt: t('Awards.award-5.alt'),
    width: '162px',
    height: '256px',
    sizes: '162px xs: md:79.9px xl:162px'
  },
  {
    href: 'https://storage-awards.com/',
    src: 'redesign/images/awards/STORAGE_AWARDS_2.webp',
    alt: t('Awards.award-6.alt'),
    width: '258px',
    height: '264px',
    sizes: '258px xs: md:81px xl:258px'
  },
  {
    href: 'https://www.crn.com/news/storage/stellar-startup-storage-vendors-to-know-in-2023?page=4',
    src: 'redesign/images/awards/CRN_STARTUPS_2023.webp',
    alt: t('Awards.award-7.alt'),
    width: '184px',
    height: '256px',
    sizes: '184px xs: md:128px xl:184px'
  },
  {
    href: 'https://techtrailblazers.com/shortlist/',
    src: 'redesign/images/awards/TT23_FINALIST.webp',
    alt: t('Awards.award-8.alt'),
    width: '256px',
    height: '170px',
    sizes: '256px xs: md:128px xl:256px'
  },
];

const SWIPER_CONFIG: Partial<InstanceType<typeof Swiper>> = {
  slidesPerView: 'auto',
  breakpoints: {
    1280: {
      spaceBetween: 32,
    },
    768: {
      spaceBetween: 24,
    },
    0: {
      spaceBetween: 8,
    },
  },
};
const DESKTOP_WIDTH = 1280;
const { width: screenWidth } = useWindowSize();

const sliderOffset = computed(() => {
  const width = screenWidth.value;
  if (width > 1280) {
    return (width - 1216) / 2;
  } else if (width > 767) {
    return 32;
  } else {
    return 16;
  }
});
</script>

<style lang="scss" scoped>
@import "$/mixins/size";
@import "$/mixins/media";
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/colors";
@import "$/variables/shadows";

[data-component-name="Awards"] {
  position: relative;

  background-color: token("surf-cont-secondary");

  padding: 8rem 0 5rem;

  @include tablet {
    padding: 6rem 0 3rem;
  }

  @include mobile {
    padding: 4rem 0;
  }

  .wrapper {
    @include wrapper(80rem);
    padding: 0 2rem;
    @include flex-center-start;

    @include mobile {
      padding: 0 1rem;
    }

    h3 {
      @include h3;
      margin-right: auto;
    }

    [data-component-name="Buttons.Regular"] {
      width: 3.5rem;
      height: 3.5rem;

      @include mobile {
        position: absolute;
        bottom: 2.4rem;

        &:first-of-type,
        &:last-of-type {
          width: calc(50% - 1.25rem);
          z-index: 2;
        }

        &:first-of-type {
          left: 1rem;
        }

        &:last-of-type {
          right: 1rem;
        }
      }

      &:last-of-type {
        margin-left: 1rem;
      }

      svg {
        fill: token("icon-primary");
      }
    }
  }

  :deep(.swiper) {
    .swiper-wrapper {
      padding: 3.5rem 0 3rem;

      @include tablet {
        padding: 3rem 0 3rem;
      }

      @include mobile {
        padding: 2rem 0 4rem;
      }

      .swiper-slide {
        position: relative;

        @include flex-center-center;

        width: 16rem;
        height: 16rem;

        img {
          display: block;

          transition: box-shadow 0.3s ease;

          &:hover {
            box-shadow: map-get($shadows, "Elevation 3");
          }
        }
      }
    }
  }
}
</style>
